import { render, staticRenderFns } from "./Privacy.vue?vue&type=template&id=4e898e33&scoped=true"
import script from "./Privacy.vue?vue&type=script&lang=js"
export * from "./Privacy.vue?vue&type=script&lang=js"
import style0 from "./Privacy.vue?vue&type=style&index=0&id=4e898e33&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e898e33",
  null
  
)

export default component.exports